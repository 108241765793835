import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { Theme } from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';

@Injectable({ providedIn: 'root' })
export class SystemApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getTheme(): Observable<Theme> {
        return this.get<Theme>('system/theme');
    }

    getTranslations(): Observable<Record<string, string>> {
        return this.get<Record<string, string>>('system/translations');
    }

    getSettings(): Observable<any> {
        return this.get<any>('system/settings');
    }
}
