import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientsService } from '../services/clients/clients.service';
import { ApiConfigService } from '../../libs/api-contract/api-config.service';

@Injectable()
export class ClientHttpInterceptor implements HttpInterceptor {
    constructor(
        private clientsService: ClientsService,
        private apiConfigService: ApiConfigService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const activeClientId = this.clientsService.activeClientId;
        const apiBaseUrl = this.apiConfigService.get()?.apiBaseUrl;

        if (activeClientId && request.url.startsWith(apiBaseUrl)) {
            const clonedRequest = request.clone({
                setHeaders: {
                    'x-clmi-portalclient': activeClientId,
                },
            });
            return next.handle(clonedRequest);
        }

        return next.handle(request);
    }
}
