import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@wdx/shared/utils';

@Injectable({
    providedIn: 'root',
})
export class LoadScriptService {
    private _window = inject(WINDOW);

    public loadScript(src: string) {
        const scriptElement = this._window.document.createElement('script');
        scriptElement.src = src;
        this._window.document.body.appendChild(scriptElement);
    }
}
